div.backgroundStart {   
    top: 0px;
    margin:auto;
    height: 100vh;
    background: transparent url("../../resources/opening_pic.png") 0% 0%
    no-repeat padding-box;
    background-size:contain;
    background-color: #c9da2a;
    opacity: 1;
}

div.introText {
  position: absolute;
  top: 15vh;
  left: 90vh;
  width: 40vh;
  height: 398px;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}

text.introText1 {
  position: relative;
  
  text-align: left;
  font: normal normal bold 2.2vh/3.3vh Poppins;
  letter-spacing: 0px;
  color: #292f2b;
}

text.introText2 {
  position: relative;
  text-align: left;
  font: normal normal normal 2.2vh/3.3vh Poppins;
  letter-spacing: 0px;
  color: #292f2b;
}

text.introText3 {
  position: relative;
  text-align: left;
  font: normal normal bold 2.2vh/3.3vh Poppins;
  letter-spacing: 0px;
  color: #593767;
}

button.startButton {
  position: absolute;
  top: calc(10vh + 2vh*35) !important;
  left: 90vh;
  width: 249px;
  height: 56px;
  background: #593767 0% 0% no-repeat padding-box;
  border-radius: 28px;
  opacity: 1;
  color: white;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1px;
  opacity: 1;
}

button.startButton:hover {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  color: #593767;
}

button.startButton:active {
  color: #CCDA2D;
}

div.startButtonArrow {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-top: 3px solid #ffffff;
  border-right: 3px solid #ffffff;
  transform: rotate(45deg);
  margin-left: 30px;
}


div.suggestionItem {
  margin-top: 20px;
  left: 284px;
  width: 660px;
  height: 212px;
  /* UI Properties */
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 29px;
  opacity: 1;
  display: flex;
}

div.pillowImage {
  width: 180px;
  height: 192px;
  margin-left: 16px;
  display: grid;
}
div.imageRectangle {
  width: 180px;
  height: 192px;
  grid-column: 1;
  grid-row: 1;
  margin-top: 12px;

  /* UI Properties */
  background: #CCDA2D 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
}

div.pillowImage img {
  grid-column: 1;
  grid-row: 1;
  width: 180px;
  margin-top: -52px;
  border-radius: 25px;
  background: transparent;
}

div.pillowVerticalLine {
  margin-top: 15px;
  width: 0px;
  height: 33px;
  /* UI Properties */
  border: 1px solid #593767;
  opacity: 1;
}

div.pillowInformation {
  margin-top: -10px;
  margin-left: 40px;
}

p.pillowTitle {
  text-align: left;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 0px;
  color: #292F2B;
  opacity: 1;
}

div.pillowStats {
  margin-top: -20px;
  display: flex;
  font-size: 14px;
  color: #593767;
}

div.pillowFilling {
  margin-right: 20px;
  text-align: center;
  width: 140px;
  height: 95px;
}
div.pillowCover {
  margin-left: 40px;
  width: 180px;
  text-align: center;
}

p.statText {
  margin-top: -10px;
}
p.titleText {
  font-weight: bold;
}

p.morePillows {
  margin-top: 65px;
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0px;
  color: #292F2B;
  opacity: 1;
}


div.pillowIconList {
  display: flex;
  margin-top: -10px;
}
div.pillowIcon {
  display: grid;
  margin-left: 12px;
  align-items: center;
  justify-items: center;
}
div.iconRectangle {
  width: 39px;
  height: 39px;
  grid-column: 1;
  grid-row: 1;

  /* UI Properties */
  background: #CCDA2D 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
}
div.pillowIcon img {
  grid-column: 1;
  grid-row: 1;
  height: 30px;
  background: transparent;

}

div.iconRectangleDark {
  width: 39px;
  height: 39px;
  grid-column: 1;
  grid-row: 1;

  /* UI Properties */
  background: #2E6732 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
}