.fillingIntroText{
    width: 468px;
    margin-top: 55px;

    text-align: left;
    font: normal normal normal 12px/22px Poppins;
    letter-spacing: 0px;
    color: #949795;
    opacity: 1;
}

.fillingIntroText .textFirstRow {
    font: normal normal bold 16px/22px Poppins;
}

.fillingIntroText .textSecondRow {
    font: normal normal normal 12px/22px Poppins;
}