/* PostureContent.css */

.header {
  top: 60px;
  display: flex;
}

.header img {
  width: 180px;
}

.header p {
  justify-content: right;
  width: 450px;
  height: 60px;
  margin-left: 50px;
  font-size: 26px;
  font-weight: bold;
  text-align: right;
}

.buttonGrid {
  left: 284px;
  width: 660px;
  height: 246px;
  margin-top: 5%;
  /* UI Properties */
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 29px;
  opacity: 1;
}

.firstRow {
  display: flex;
  margin-left: 83px;
}

.postureButton {
  margin-left: 30px;
  margin-top: 30px;
  width: 125px;
  height: 180px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 2px 7px #3131310d;
  border-radius: 26px;
  opacity: 1;
  border: 0cm;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;

  /* Font */
  text-align: center;
  font: normal normal bold 14px/18px Poppins;
  font-weight: bold;
  letter-spacing: 0px;
  color: #292f2b;
  opacity: 0.5;
}

.selectedPostureButton {
  margin-left: 30px;
  margin-top: 30px;
  width: 125px;
  height: 180px;
  /* UI Properties */
  background: #593767 0% 0% no-repeat padding-box;
  box-shadow: 4px 2px 7px #3131310d;
  border-radius: 26px;
  opacity: 1;
  border: 0cm;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;

  /* Font */
  text-align: center;
  font: normal normal bold 14px/18px Poppins;
  font-weight: bold;
  letter-spacing: 0px;
  color: #F9F9F9;
  opacity: 0.5;
}

.buttonGrid img {
  margin: 20px;
  height: 65px;
}

button.nextPostureButton {
  width: 134px;
  height: 56px;
  margin-left: 18px;
  background: #593767 0% 0% no-repeat padding-box;
  border-radius: 28px;
  border: 0px;
  opacity: 1;
  color: white;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  opacity: 1;
}
button.backPostureButton {
  width: 56px;
  height: 56px;
  margin-left: 470px;
  margin-top: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 28px;
  border: 2px solid #593767;
  opacity: 1;
}
div.backButtonArrow {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-top: 3px solid #593767;
  border-right: 3px solid #593767;
  transform: rotate(225deg);
}