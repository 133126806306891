div.backgroundStart {   
    top: 0px;
    margin:auto;
    height: 100vh;
    background: transparent url("../../resources/opening_pic.png") 0% 0%
    no-repeat padding-box;
    background-size:contain;
    background-color: #c9da2a;
    opacity: 1;
}

div.introText {
  position: absolute;
  top: 10vh;
  left: 90vh;
  width: 40vh;
  height: 398px;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}

text.introText1 {
  position: relative;
  
  text-align: left;
  font: normal normal bold 2.2vh/3.3vh Poppins;
  letter-spacing: 0px;
  color: #292f2b;
}

text.introText2 {
  position: relative;
  text-align: left;
  font: normal normal normal 2.2vh/3.3vh Poppins;
  letter-spacing: 0px;
  color: #292f2b;
}

text.introText3 {
  position: relative;
  text-align: left;
  font: normal normal bold 2.2vh/3.3vh Poppins;
  letter-spacing: 0px;
  color: #593767;
}

button.startButton {
  position: absolute;
  top: calc(10vh + 2vh*33) !important;
  left: 90vh;
  width: 249px;
  height: 56px;
  background: #593767 0% 0% no-repeat padding-box;
  border-radius: 28px;
  opacity: 1;
  color: white;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1px;
  opacity: 1;
}

button.startButton:hover {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  color: #593767;
}

button.startButton:active {
  color: #CCDA2D;
}

div.startButtonArrow {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-top: 3px solid #ffffff;
  border-right: 3px solid #ffffff;
  transform: rotate(45deg);
  margin-left: 30px;
}