/* Sidebar.css */

#sidebar {
  min-height: 100vh;
  height: auto !important;
  height: 100%;
  width: 260px;
  color: white;
  padding: 20px;
  padding-top: 40px;
  box-sizing: border-box;
  background: #ccda2d 0% 0% no-repeat padding-box;
  opacity: 1;
}

.clickable-text {
  cursor: pointer; /* Change cursor to pointer on hover */
}

.element {
  margin-bottom: 10px;
  cursor: pointer;
  text-align: left;
  font: normal normal bold 16px/29px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  display: flex;
  align-items: center;
}

.selectedCircle {
  width: 16px;
  height: 37px;
  margin-right: 16px;
  /* UI Properties */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
}

.unselectedCircle {
  width: 16px;
  height: 16px;
  margin-right: 16px;
  margin-top: 5px;
  /* UI Properties */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
}

.menuRow {
  display: flex;
  height: 37px;
  margin-bottom: 22px;
  margin-left: 55px;
  cursor: pointer;
}