.buttonGridAllergies {

    left: 284px;
    width: 660px;
    height: 441px;
    margin-top: 5%;
    /* UI Properties */
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border-radius: 29px;
    opacity: 1;
}

.allergiesFirstRow {
    display: flex;
    padding-left: 67px;
}
.allergiesSecondRow {
    display: flex;
    padding-left: 67px;
}

.buttonGridAllergies img {
    margin: 20px;
    height: 65px;
}


.allergiesButton {
    margin-left: 30px;
    margin-top: 30px;
    width: 125px;
    height: 180px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 4px 2px 7px #3131310d;
    border-radius: 26px;
    opacity: 1;
    border: 0cm;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
  
    /* Font */
    text-align: center;
    font: normal normal bold 12px/16px Poppins;
    font-weight: bold;
    letter-spacing: 0px;
    color: #292f2b;
    opacity: 0.5;
  }
  
  .selectedAllergiesButton {
    margin-left: 30px;
    margin-top: 30px;
    width: 125px;
    height: 180px;
    /* UI Properties */
    background: #593767 0% 0% no-repeat padding-box;
    box-shadow: 4px 2px 7px #3131310d;
    border-radius: 26px;
    opacity: 1;
    border: 0cm;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  
    /* Font */
    text-align: center;
    font: normal normal bold 12px/16px Poppins;
    font-weight: bold;
    letter-spacing: 0px;
    color: #f9f9f9;
    opacity: 0.5;
  }  