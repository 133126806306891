button.default {
  width: 52px;
  height: 52px;
  margin-right: 20px; /* Adjust the margin between buttons */
  margin-top: 10px;
  border-radius: 50%; /* Make the button round */
  border: 1px solid #ccc; /* Add a border for better visibility */
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}

button.selected {
  width: 52px;
  height: 52px;
  margin-right: 20px; /* Adjust the margin between buttons */
  margin-top: 10px;
  border-radius: 50%; /* Make the button round */
  border: 1px solid #ccc; /* Add a border for better visibility */
  color: #fff; /* White text, you can adjust this */
  background: #593767 0% 0% no-repeat padding-box;
  opacity: 1;
}

div.background {
  position: relative;
  height: 147px;
  width: 100vh;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 29px;
  opacity: 1;
}

div.scale {
  position: relative;
  top: 11px;
  left: 32px;
}

p.question {
  position: relative;
  top: 24px;
  left: 32px;
  text-align: left;
  font: normal normal bold 16px/22px Poppins;
  letter-spacing: 0px;
  color: #292f2b;
  opacity: 1;
}

.slider-checkbox-container {
  position: absolute;
  top: 10px;
  right: 30px;
  width: 90px;
  height: 56px;
  text-align: center;
}

.slider-checkbox {
  position: relative;
  display: block;
  width: 60px;
  height: 34px;
}

.slider-checkbox input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.slider-checkbox input:checked + .slider {
  background-color: #ccda2d;
}

.slider-checkbox input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.toggleTextOn {
  position: relative;
  text-align: center;
  font: normal normal normal 12px/22px Poppins;
  letter-spacing: 0px;
  color: #ccda2d;
  opacity: 1;
}

.toggleTextOff {
  position: relative;
  text-align: center;
  font: normal normal normal 12px/22px Poppins;
  letter-spacing: 0px;
  color: #949795;
  opacity: 1;
}
