/* content.css */

#content {
    flex-grow: 1;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
  }
  
  #fixed-header {
    /* position: fixed; */
    /* top: 0; */
    /* right: 0; */
    /*left: 200px; */ /* Width of the sidebar */
    background-color: #333;
    color: white;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
  }
  
  .hidden {
    display: none;
  }
  
  /* Add this style to make the first element visible by default */
  #element1 {
    display: block;
  }
  