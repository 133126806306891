.buttonGridPhysical {
  left: 284px;
  width: 660px;
  height: 246px;
  padding-left: 0px;
  margin-top: 5%;
  /* UI Properties */
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 29px;
  opacity: 1;
}

.physicalFirstRow {
  display: flex;
}

.physicalPainButton {
  width: 125px;
  height: 180px;
  margin-top: 30px;
  margin-left: 30px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 2px 7px #3131310d;
  border-radius: 26px;
  opacity: 1;
  border: 0cm;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;

  /* Font */
  text-align: center;
  font: normal normal bold 14px/18px Poppins;
  font-weight: bold;
  letter-spacing: 0px;
  color: #292f2b;
  opacity: 0.5;
}

.selectedPhysicalPainButton {
  width: 125px;
  height: 180px;
  margin-top: 30px;
  margin-left: 30px;
  /* UI Properties */
  background: #593767 0% 0% no-repeat padding-box;
  box-shadow: 4px 2px 7px #3131310d;
  border-radius: 26px;
  opacity: 1;
  border: 0cm;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;

  /* Font */
  text-align: center;
  font: normal normal bold 14px/18px Poppins;
  font-weight: bold;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 0.5;
}

.buttonGridPhysical img {
  margin: 20px;
  height: 65px;
}
